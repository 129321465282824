
import Field from "@/components/crud/fields/FieldMixin.vue";
import Vue from "vue";

export default Vue.extend({
  name: "KCheckbox",
  mixins: [Field],
  model: {
    prop: "input-value",
    event: "change",
  },
  props: {
    showHorizontalLabel: {
      type: Boolean,
      default: false,
    },
  },
});
