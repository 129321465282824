import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosPromise } from "axios";

export function clientAutocomplete(
  data: PaginatedRequest
): AxiosPromise<PaginatedResponse<{ id: number; name: string }[]>> {
  return getPaginated("client/autocomplete", data);
}
