
import Vue, { PropType } from "vue";
import { DataTableHeader } from "vuetify";
import ColumnBoolean from "./ColumnBoolean.vue";
import Enum from "./Enum.vue";
import Text from "./Text.vue";
import DateColumn from "./Date.vue";

export default Vue.extend({
  name: "ColumnTypeSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object as PropType<DataTableHeader & { type?: string }>,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: false,
      default: "",
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    component() {
      // @TODO is this lazy loaded?
      switch (this.header.type) {
        default:
        case "text":
          return Text;
        case "boolean":
          return ColumnBoolean;
        case "enum":
          return Enum;
        case "date":
          return DateColumn;
      }
    },
  },
});
