
import Vue, { PropType } from "vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import MapperAutocomplete from "@/modules/conversion/components/MapperAutocomplete.vue";
import KDateTimeField from "@/components/crud/fields/KDateTimeField.vue";
import MessageTypeSelect from "@/modules/conversion/components/MessageTypeSelect.vue";
import ClientAutocomplete from "@/modules/conversion/components/ClientAutocomplete.vue";

type ComponentData = {
  internalFilters: Record<string, any>;
};

export default Vue.extend({
  name: "ConversionFilter",
  components: {
    ClientAutocomplete,
    MessageTypeSelect,
    KDateTimeField,
    KCheckbox,
    MapperAutocomplete,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    internalFilters: {
      messageType: [],
    },
  }),
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters;
      },
      immediate: true,
      deep: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
      },
      deep: true,
    },
  },
});
