
import Vue, { PropType, VueConstructor } from "vue";
import { DataOptions, DataPagination, DataTableHeader } from "vuetify";
import { AxiosResponse } from "axios";
import {
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import deepEqual from "deep-equal";
import ColumnTypeSelector from "@/modules/crudTable/components/ColumnTypes/ColumnTypeSelector.vue";
import { mapGetters, mapMutations } from "vuex";
import { RouteSetting } from "@/modules/crudTable/store/crudTableStore";
import FilterBar from "@/modules/crudTable/components/FilterBar.vue";
import { getOnlySetQueryParameters } from "@/application/util/queryString";
import EventBus from "@/application/eventBus";

interface ComponentData {
  response: {
    data: unknown[];
    meta?: PaginatedResponse["meta"];
  };
  options: DataOptions;
  search: string;
  searchDebounce: number;
  isLoading: boolean;
  isOpenFilter: boolean;
  params: Record<string, any> | undefined;
}

interface additionalHeader {
  value: string;
  type?: string;
  columnSettings?: Record<string, any>;
  valueFormatter?: (value: unknown) => string | number;
}
export type CrudTableHeader = additionalHeader & Partial<DataTableHeader>;

export default Vue.extend({
  name: "KCrudTable",
  components: { FilterBar, ColumnTypeSelector },
  props: {
    request: {
      type: Function as PropType<
        (data: PaginatedRequest) => Promise<AxiosResponse<PaginatedResponse>>
      >,
      required: true,
    },
    headers: {
      type: Array as PropType<CrudTableHeader[]>,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
    withoutPagination: {
      type: Boolean,
      default: false,
    },
    withoutSearch: {
      type: Boolean,
      default: false,
    },
    withoutSaveSettings: {
      type: Boolean,
      default: false,
    },
    filterComponent: {
      type: Function as PropType<VueConstructor>,
    },
    deleteRequest: {
      type: Function,
    },
    defaultFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (): ComponentData => ({
    response: {
      data: [],
      meta: undefined,
    },
    search: "",
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    searchDebounce: 0,
    isLoading: false,
    isOpenFilter: false,
    params: {},
  }),
  computed: {
    ...mapGetters("crudTable", ["getRouteSetting"]),
    currentRouteSettings(): RouteSetting {
      return this.getRouteSetting(this.$route.name);
    },
    pagination(): DataPagination | undefined {
      if (this.withoutPagination && this.response.data) {
        return {
          page: 1,
          itemsPerPage: this.response.data.length,
          pageStart: 0,
          pageStop: 1,
          pageCount: 1,
          itemsLength: this.response.data.length,
        };
      }

      if (this.response.meta) {
        return {
          page: this.response.meta.currentPage,
          itemsPerPage: this.response.meta.perPage,
          pageStart: this.response.meta.from - 1,
          pageStop: this.response.meta.to,
          pageCount: this.response.meta.lastPage,
          itemsLength: this.response.meta.total,
        };
      }
      return undefined;
    },
    vuetifyHeaders(): (additionalHeader & DataTableHeader)[] {
      return [
        ...this.headers.map((header) => ({
          ...header,
          text: this.$t(`${this.translationPrefix}.${header.value}`) as string,
        })),
        {
          text: this.$t("crudTable.actions") as string,
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    onlySetParams(): Record<string, any> {
      if (!this.params) {
        return {};
      }
      return getOnlySetQueryParameters(this.params);
    },
    hasFilters(): boolean {
      return !!Object.values(this.onlySetParams).length;
    },
    selectedItemsCount(): number {
      return this.$attrs?.value?.length || 0;
    },
    itemCount(): string {
      if (!this.pagination?.itemsLength) return "";

      return this.selectedItemsCount
        ? `(${this.selectedItemsCount} geselecteerd)`
        : `(${this.pagination.itemsLength})`;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.ObtainAndSetSettings();
      },
    },
    options: {
      handler(options, oldOptions) {
        if (!deepEqual(options, oldOptions)) {
          clearTimeout(this.searchDebounce);
          this.getData();
        }
      },
      deep: true,
    },
    search: "getDataDebounced",
    isOpenFilter: "saveSettings",
    params: { handler: "getDataDebounced", deep: true },
  },
  created() {
    this.params = { ...this.defaultFilters };
    this.ObtainAndSetSettings();
    this.getData();
  },
  methods: {
    ...mapMutations("crudTable", ["setRouteSetting"]),
    getDataDebounced() {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(async () => {
        if (this.options.page !== 1) {
          this.options.page = 1;
        } else {
          await this.getData();
          this.clearSelectedItems();
        }
      }, 700);
    },
    clearSelectedItems() {
      this.$emit("input", []);
    },
    async getData() {
      this.isLoading = true;
      this.saveSettings();
      const response = await this.request({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        sortBy: this.options.sortBy[0],
        descending:
          this.options.sortDesc[0] === undefined
            ? undefined
            : !!this.options.sortDesc[0] || false,
        params: this.onlySetParams,
      });
      this.response = response.data as {
        data: unknown[];
        meta?: PaginatedResponse["meta"];
      };
      this.isLoading = false;
    },
    hasClickListener(type: string): boolean {
      return !!this.$listeners && !!this.$listeners[`click:${type}`];
    },
    saveSettings() {
      if (this.withoutSaveSettings) {
        return;
      }
      this.setRouteSetting({
        routeName: this.$route.name,
        settings: {
          pagination: {
            page: this.options.page,
            perPage: this.options.itemsPerPage,
            search: this.search,
            sortBy: this.options.sortBy,
            descending: this.options.sortDesc,
            params: this.params,
          },
          isOpenFilter: this.isOpenFilter,
        },
      });
    },
    ObtainAndSetSettings() {
      if (this.withoutSaveSettings || !this.currentRouteSettings) {
        return;
      }
      this.options.page = this.currentRouteSettings.pagination.page;
      this.options.itemsPerPage = this.currentRouteSettings.pagination.perPage;
      this.search = this.currentRouteSettings.pagination.search;
      this.options.sortBy = this.currentRouteSettings.pagination.sortBy;
      this.options.sortDesc = this.currentRouteSettings.pagination.descending;
      this.params = this.currentRouteSettings.pagination.params;
      this.isOpenFilter = this.currentRouteSettings.isOpenFilter;
    },
    handleReset() {
      this.search = "";
      this.options = {
        ...this.options,
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
      };
      this.params = { ...this.defaultFilters };
    },
    handleDelete(item: any) {
      if (typeof this.deleteRequest !== "function") {
        throw "KCrudTable::tried to call deleteRequest, but it doesn't exist";
      }
      if (!item.id) {
        throw "KCrudTable::item does not have an id";
      }
      EventBus.$emit("confirm", {
        title: this.$t("global.confirmationTitle"),
        body: this.$t("global.confirmationDeleteBody"),
        confirmCallback: async () => {
          await this.deleteRequest(item.id);
          await this.getData();
        },
      });
    },
  },
});
