
import Vue, { PropType } from "vue";
import { CrudTableHeader } from "@/modules/crudTable/components/KCrudTable.vue";

export default Vue.extend({
  name: "TextColumn",
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object as PropType<CrudTableHeader>,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
});
