var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[(_vm.filterComponent)?_c('FilterBar',{attrs:{"filters":_vm.params,"component":_vm.filterComponent},on:{"update:filters":function($event){_vm.params=$event}},model:{value:(_vm.isOpenFilter),callback:function ($$v) {_vm.isOpenFilter=$$v},expression:"isOpenFilter"}}):_vm._e(),(_vm.$scopedSlots.actions)?_c('v-card-actions',{staticClass:"pa-4 justify-start"},[_vm._t("actions")],2):_vm._e(),_c('v-card-title',[_vm._t("title"),_c('span',[_vm._v(" "+_vm._s(_vm.itemCount))]),_c('VSpacer'),_c('v-col',{staticClass:"pa-0",attrs:{"sm":"3"}},[(!_vm.withoutSearch)?_c('VTextField',{staticClass:"col-sm-3",attrs:{"label":_vm.$t('crudTable.search'),"append-icon":"$search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-badge',{staticClass:"align-self-center",attrs:{"value":_vm.hasFilters,"color":"accent","dot":""}},[(_vm.filterComponent)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isOpenFilter = !_vm.isOpenFilter}}},[_c('v-icon',[_vm._v("$filter")])],1):_vm._e()],1)],2),(!_vm.pagination)?_c('VSkeletonLoader',{attrs:{"type":"table-thead, table-tbody, table-tfoot","height":"60vh"}}):_c('v-data-table',_vm._g(_vm._b({class:{ 'clickable-rows': _vm.hasClickListener('row') },attrs:{"items":_vm.response.data,"headers":_vm.vuetifyHeaders,"options":_vm.options,"server-items-length":_vm.pagination.itemsLength,"footer-props":{
        pagination: _vm.pagination,
        itemsPerPageOptions: [10, 25, 50, 100],
        showFirstLastPage: true,
      },"loading":_vm.isLoading,"fixed-header":"","height":"60vh","disable-pagination":_vm.withoutPagination},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}}),_vm._l((_vm.$slots),function(_,slotName){return {key:slotName,fn:function(){return [_vm._t(slotName)]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:`item.${header.value}`,fn:function({ isMobile, item, value }){return [(typeof _vm.$scopedSlots[`item.${header.value}`] !== 'function')?_c('ColumnTypeSelector',{key:header.value,attrs:{"is-mobile":isMobile,"item":item,"header":header,"value":header.valueFormatter ? header.valueFormatter(value) : value}}):_vm._t(`item.${header.value}`,null,null,{
            isMobile,
            item,
            header,
            value: header.valueFormatter ? header.valueFormatter(value) : value,
          })]}}}),{key:"item.actions",fn:function({ item }){return [_vm._t("prepend-actions",null,{"item":item}),(_vm.hasClickListener('show'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:show', item)}}},[_c('v-icon',[_vm._v("$view")])],1):_vm._e(),(_vm.hasClickListener('edit'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:edit', item)}}},[_c('v-icon',[_vm._v("$edit")])],1):_vm._e(),(_vm.deleteRequest && item.canDelete !== false)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e(),_vm._t("append-actions",null,{"item":item})]}},{key:"footer.prepend",fn:function(){return [_c('VSpacer'),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":_vm.handleReset}},[_vm._v(" "+_vm._s(_vm.$t("actions.resetFilters"))+" ")])]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }