
import Vue from "vue";
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import { clientAutocomplete } from "@/modules/conversion/api/clientAutocomplete";

export default Vue.extend({
  name: "ClientAutocomplete",
  components: { KPaginatedAutocomplete },
  methods: {
    clientAutocomplete,
  },
});
