
import Vue, { PropType } from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "IsActive",
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object as PropType<DataTableHeader & { type?: string }>,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isTrue() {
      return this.header.typeParameters.revertValue
        ? !this.value
        : !!this.value;
    },
  },
});
