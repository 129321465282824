
import Vue from "vue";
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import { mapperAutocomplete } from "@/modules/conversion/api/mapperAutocomplete";

export default Vue.extend({
  name: "MapperAutocomplete",
  components: { KPaginatedAutocomplete },
  methods: {
    mapperAutocomplete,
  },
});
