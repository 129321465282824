
import Vue, { PropType } from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "Enum",
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object as PropType<
        DataTableHeader & { translationPrefix?: string }
      >,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
});
