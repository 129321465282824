
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import { MessageType } from "@/modules/conversion/enums";

export default Vue.extend({
  name: "MessageTypeSelect",
  components: { KSelect },
  props: ["value"],
  data: () => ({
    items: Object.keys(MessageType),
  }),
  computed: {
    selectedAllOptions() {
      return this.items.length === this.value.length;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllOptions) {
          this.inputVal = [];
        } else {
          this.inputVal = this.items.slice();
        }
      });
    },
  },
});
