
import Vue from "vue";
import KCrudTable from "@/modules/crudTable/components/KCrudTable.vue";
import dayjs from "@/plugins/dayjs";
import { conversionIndex } from "@/modules/conversion/api/conversionIndex";
import ConversionFilter from "@/modules/conversion/components/ConversionFilter.vue";
import { MessageType } from "@/modules/conversion/enums";

export default Vue.extend({
  name: "ConversionTable",
  components: { KCrudTable },
  data: () => ({
    headers: [
      { value: "id" },
      { value: "name" },
      { value: "type" },
      { value: "reference" },
      { value: "clientName" },
      {
        value: "createdAt",
        valueFormatter: (date: string) =>
          date ? dayjs(date).format("LLL") : "",
      },
      { value: "statusCode" },
    ],
    conversionIndex: conversionIndex,
    filterComponent: ConversionFilter,
    defaultFilters: {
      errorOnly: true,
      from: dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
      to: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      messageType: Object.keys(MessageType).filter(
        (type) => type !== MessageType.Product
      ),
    },
  }),
});
