import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosPromise } from "axios";

interface Conversion {
  id: number;
}

export function conversionIndex(
  data: PaginatedRequest
): AxiosPromise<PaginatedResponse<Conversion[]>> {
  return getPaginated("conversion", data);
}
