
import Vue from "vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "KDateTimeField",
  components: { KTextField },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    internalValue: "",
  }),
  watch: {
    value: {
      immediate: true,
      handler(value: string) {
        this.internalValue = value
          ? dayjs(value).format("YYYY-MM-DDTHH:mm:ss")
          : "";
      },
    },
    internalValue: {
      immediate: true,
      handler(value: string) {
        this.$emit(
          "input",
          value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : ""
        );
      },
    },
  },
});
