export enum MessageType {
  Product = "Product",
  SalesOrder = "SalesOrder",
  SalesOrderCompleted = "SalesOrderCompleted",
  SalesOrderStatus = "SalesOrderStatus",
  PurchaseOrderStatus = "PurchaseOrderStatus",
  StockMutation = "StockMutation",
  Shipment = "Shipment",
  StockValue = "StockValue",
  Stock = "Stock",
  PurchaseOrder = "PurchaseOrder",
  Receipt = "Receipt",
}
