var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('k-crud-table',{attrs:{"headers":_vm.headers,"request":_vm.conversionIndex,"translation-prefix":"conversion.headers","filter-component":_vm.filterComponent,"default-filters":_vm.defaultFilters},on:{"click:row":function($event){return _vm.$router.push({
          name: 'conversion.show',
          params: { conversionId: $event.id },
        })},"click:show":function($event){return _vm.$router.push({
          name: 'conversion.show',
          params: { conversionId: $event.id },
        })}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("conversion.title")))]},proxy:true},{key:"item.statusCode",fn:function({ item }){return [(item.statusCode || item.statusCode === 0)?_c('v-chip',{attrs:{"color":item.statusCode < 200 || item.statusCode > 299 ? 'error' : 'success'}},[_vm._v(" "+_vm._s(item.statusCode)+" ")]):_vm._e()]}},{key:"item.reference",fn:function({ item }){return [(item.reference)?_c('div',[(item.reference.length <= 1)?_c('div',[_vm._v(_vm._s(item.reference[0]))]):_c('v-menu',{attrs:{"open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.reference[0])+" +"+_vm._s(item.reference.length - 1)+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.reference),function(item,index){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(index > 0),expression:"index > 0"}],key:index,attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }